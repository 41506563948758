<template>
  <div class="container" id="content">
    <TableBox
      ref="tableBox"
      :tableHeader="tableHeader"
      :rowBtns="rowBtns"
      request="/inputPurchase/pageUse"
      @addOption="addOption"
    ></TableBox>

    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";

export default {
  name: "lawsAReg",
  components: { UpdataModal, TableBox },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},

      tableHeader: [
        {
          label: "农事任务",
          key: "farmTaskId",
          type: "Input",
        },
        {
          label: "产品名称",
          key: "inputName",
          type: "Input",
        },
        {
          label: "规格",
          key: "unit",
          type: "Input",
        },
        {
          label: "使用数量",
          key: "useNumber",
          type: "InputNumber",
        },
        {
          label: "出库人",
          key: "useUser",
          type: "Input",
        },
        {
          label: "出库人联系方式",
          key: "useUserTel",
          type: "Input",
        },
        {
          label: "出库时间",
          key: "useTime",
          type: "DateTime",
        },
        {
          label: "用途",
          key: "usePurpose",
          type: "Textarea",
        },
       
      ],
      rowBtns: [
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/inputPurchase/deleteUse/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.$refs.tableBox.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 新增
    addOption() {
      this.$message.success("请在【农事任务】菜单中使用投入品，投入品关联农事任务！")
      /*this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
          // title: 'biaoti'
        };
        this.$refs.updata.resetForm();
      });*/
    },

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
</style>
